@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #dcdad7;
  background-color: #4b7b4b;
  font-family: "Roboto Mono", monospace;
}
nav a {
  text-decoration: none;
  transition: letter-spacing 600ms, color 600ms, font-size 600ms;
  word-break: break-all;
  cursor: auto;
  font-weight: bold;
}

nav a:hover {
  letter-spacing: 0.5em;
  font-size: 20px;
  color: #f2a900;
}
